<script>
import Toast from '@/components/toast/Toast.vue';

export default {
  name: "ToastManager",
  components: {
    Toast,
  },
  data() {
    return {
      toasts: []
    };
  },
  methods: {
    addToast(toast) {
      this.toasts.push({
        ...toast
      });
    },

    removeToast(index) {
      this.toasts.splice(index, 1);
    },

    updateToast(id, updatedProps) {
      const toast = this.toasts.find(t => t.id === id);
      if (toast) {
        Object.assign(toast, updatedProps);
        toast.progress = 100;
      }
    },
  },
};
</script>

<template>
  <transition-group
      name="toast"
      tag="div"
      class="toast-manager d-flex flex-column"
      appear
  >
    <Toast
        v-for="(toast, index) in toasts"
        :key="toast.id"
        :description="toast.description"
        :title="toast.title"
        :type="toast.type"
        :duration="toast.duration"
        :initial-progress="toast.progress"
        @hide="removeToast(index)"
        :index="index"
    >

    </Toast>
  </transition-group>
</template>

<style scoped>
.toast-manager {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  gap: 5px;
  height: 100%;
  min-width: 340px;
  pointer-events: none;
}

.toast-enter-active, .toast-move {
  transition: 0.4s ease all;
}

.toast-enter-from, .toast-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.toast-leave-active {
  transition: 0.4s ease all;
  position: absolute;
}
</style>