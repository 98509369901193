import router from "@/router";
import App from "@/App.vue";
import {createApp} from "vue";
import '@/assets/css/font.css'
import '@/assets/scss/bootstrap.scss'
import CKEditor from "@ckeditor/ckeditor5-vue";
import InfinityMenu from "@/components/InfinityMenu.vue";

import axios from "axios";
import {createPinia} from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Debug from "@/components/utils/Debug.vue";
import ToastPlugin from "@/components/toast/Tostify";

axios.defaults.withCredentials = true;
axios.defaults.timeout = process.env.VUE_APP_API_TIMEOUT;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common = {
    "Content-Type": "application/json",
    "Accept": "application/json"
}

axios.defaults.transformRequest = [(data) => {
    if (data instanceof FormData) {
        return data
    }

    if (!data) {
        data = {}
    }

    return JSON.stringify(data);
}];

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(router);
app.use(CKEditor);
app.use(pinia)
app.use(ToastPlugin);

app.component("InfinityMenu", InfinityMenu);
app.component('Debug', Debug);

app.mount("#app");