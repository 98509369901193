import {toastInstance} from "@/components/toast/Tostify";
import {errorUtil} from "@/utils/ErrorUtil";

export const CopyUtil = {
    copy
};

async function copy(text) {
    const toast = toastInstance.loading('Copying...');

    try {
        await navigator.clipboard.writeText(text);

        toastInstance.update(toast.id, {
            description: 'Copied to clipboard',
            type: 'success'
        })
    } catch (error) {
        errorUtil.catchErrors(error, toast);
    }
}