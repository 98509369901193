<script>
import {LoadingType} from "@/type/LoadingType";

export default {
  name: 'Loading',

  props: {
    type: {
      type: String,
      default: LoadingType.LARGE
    }
  }
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-center h-100 w-100 my-2" :data-spiner="type">
    <div class="loading-spinner"></div>
  </div>
</template>

<style scoped lang="scss">
[data-spiner="small"] {
  .loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-left: 2px solid #FFF;
  }
}

[data-spiner="large"] {
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left: 4px solid #FFF;
  }
}

.loading-spinner {
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>