export default [
    {
        path: "/",
        name: "/",
        redirect: "/login",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/pages/Home.vue")
    },
    {
        path: "/dashboard/statistic/ItemShop",
        name: "ItemShop Statistic",
        component: () => import("@/views/pages/statistic/ItemShop.vue")
    },
    {
        path: "/dashboard/tickets/bugs",
        name: "Ticket Bugs",
        component: () => import("@/views/pages/tickets/Bugs.vue")
    },
    {
        path: "/dashboard/tickets/complaints",
        name: "Ticket Complaints",
        component: () => import("@/views/pages/tickets/Complaints.vue")
    },
    {
        path: "/dashboard/tickets/suggestions",
        name: "Ticket Suggestions",
        component: () => import("@/views/pages/tickets/Suggestions.vue")
    },
    {
        path: "/dashboard/tickets/reports",
        name: "Ticket Reports",
        component: () => import("@/views/pages/tickets/Reports.vue")
    },
    {
        path: "/dashboard/chat/management",
        name: "Chat Management",
        component: () => import("@/views/pages/chat/ChatManagement.vue")
    },
    {
        path: "/dashboard/permissions/users",
        name: "Permission Users",
        component: () => import("@/views/pages/permissions/Users.vue")
    },
    {
        path: "/dashboard/permissions/roles",
        name: "Permission Roles",
        component: () => import("@/views/pages/permissions/Roles.vue")
    },
    {
        path: "/dashboard/chat/banned-words",
        name: "Banned Words",
        component: () => import("@/views/pages/chat/ChatManagement.vue")
    },
    {
        path: "/dashboard/users",
        name: "Users",
        component: () => import("@/views/pages/players/Players.vue")
    },
    {
        path: "/dashboard/entity-map/players",
        name: "Players",
        component: () => import("@/views/pages/entityMap/Players.vue")
    },
    {
        path: "/dashboard/entity-map/spawners",
        name: "Spawners",
        component: () => import("@/views/pages/entityMap/Spawners.vue")
    },
    {
        path: "/dashboard/entity-map/npcs",
        name: "NPCs",
        component: () => import("@/views/pages/entityMap/Npcs.vue")
    },
    {
        path: "/dashboard/entity-map/teleports",
        name: "Teleports",
        component: () => import("@/views/pages/entityMap/Teleports.vue")
    },
    {
        path: "/dashboard/quests/main-quests",
        name: "Main Quests",
        component: () => import("@/views/pages/quests/Main.vue")
    },
    {
        path: "/dashboard/quests/side-quests",
        name: "Side Quests",
        component: () => import("@/views/pages/quests/Side.vue")
    },
    {
        path: "/dashboard/game-content/cases-packs",
        name: "Cases & Packs",
        component: () => import("@/views/pages/gameContent/CasesPack.vue")
    },
    {
        path: "/dashboard/game-content/entities",
        name: "Entities",
        component: () => import("@/views/pages/gameContent/Entities.vue")
    },
    {
        path: "/dashboard/game-content/equipment",
        name: "Equipment",
        component: () => import("@/views/pages/gameContent/Equipment.vue")
    },
    {
        path: "/dashboard/game-content/items",
        name: "Items",
        component: () => import("@/views/pages/gameContent/Items.vue")
    },
    {
        path: "/dashboard/game-content/itemshop",
        name: "Item Shop",
        component: () => import("@/views/pages/gameContent/itemshop/ItemShop.vue")
    },
    {
        path: "/dashboard/game-content/itemshop-layout",
        name: "ItemShop Layout",
        component: () => import("@/views/pages/gameContent/itemshop/ItemShopLayout.vue")
    },
    {
        path: "/dashboard/game-content/cosmetics",
        name: "Cosmetics",
        component: () => import("@/views/pages/gameContent/Cosmetics.vue")
    },
    {
        path: "/dashboard/game-content/skills",
        name: "Skills",
        component: () => import("@/views/pages/gameContent/Skills.vue")
    },
    {
        path: "/dashboard/wiki/about-game",
        name: "About Game",
        component: () => import("@/views/pages/wiki/AboutGame.vue")
    },
    {
        path: "/dashboard/wiki/game-lore",
        name: "Game Lore",
        component: () => import("@/views/pages/wiki/GameLore.vue")
    },
    {
        path: '/login',
        name: "Login",
        component: () => import('@/views/pages/account/Login.vue')
    },
    {
        path: '/mobile',
        name: 'Mobile',
        component: () => import('@/views/pages/utility/Mobile.vue')
    },
    {
        path: '/error-404',
        name: 'Error-404',
        component: () => import('@/views/pages/utility/Error404')
    },
    {
        path: '/error-500',
        name: 'Error-500',
        component: () => import('@/views/pages/utility/Error500')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('@/views/pages/utility/Maintenance.vue')
    },
    {
        path: "/:catchAll(.*)",
        name: 'error-404',
        redirect: '/error-404',
    }
]