import {createRouter, createWebHistory} from 'vue-router'
import routes from './routes'
import axios from "axios";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

// function isMobileDevice() {
//     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
// }

let authPromise = null
const AUTH_CACHE_DURATION = 5 * 60 * 1000
let lastAuthTime = 0

const checkAuth = async () => {
    const now = Date.now()
    if (authPromise && now - lastAuthTime < AUTH_CACHE_DURATION) {
        return authPromise
    }

    authPromise = axios.post('/account/auth', [])
        .then(() => {
            lastAuthTime = now
            return true
        })
        .catch(() => {
            lastAuthTime = now
            return false
        })

    return authPromise
}

router.beforeResolve(async (to, from, next) => {
    const isDashboard = to.path.includes('/dashboard')

    if (isDashboard) {
        const isAuthenticated = await checkAuth()
        if (isAuthenticated) {
            next()
        } else {
            next({ name: 'Login' })
        }
    } else {
        next()
    }
})

// router.beforeEach((to, from, next) => {
//     if (to.name !== 'Mobile' && isMobileDevice()) {
//         next({ name: 'Mobile' })
//     } else {
//         next()
//     }
// })

export default router