<script>
import {CopyUtil as copyUtil} from "@/utils/CopyUtil";

export default {
  name: 'Debug',
  props: {
    jsonData: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
    }
  },

  data() {
    return {
      isTestEnv: process.env.VUE_APP_ENV === 'test'
    }
  },

  computed: {
    copyUtil() {
      return copyUtil
    },
    highlightedJson() {
      return this.prettyJSON(this.jsonData);
    },
  },

  methods: {
    copyJson() {
      this.copyUtil.copy(JSON.stringify(this.jsonData));
    },

    prettyJSON(json) {
      if (json) {
        const jsonString = JSON.stringify(json, null, 2);
        const escapedJson = jsonString
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .trim();

        return escapedJson.replace(
            /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE]?\d+)?|\[.*?]|\{.*?})/g,
            (match) => {
              let cls = "key";
              if (/^"/.test(match)) {
                cls = /:$/.test(match) ? "key" : "string";
              } else if (/true|false/.test(match)) {
                cls = "boolean";
              } else if (/null/.test(match)) {
                cls = "null";
              } else if (/^\[.*]$/.test(match)) {
                cls = "array";
              } else if (/^\{.*}$/.test(match)) {
                cls = "object";
              } else if (!isNaN(match) && match.trim() !== '') {
                cls = "number";
              }
              return `<span class="${cls}">${match}</span>`;
            }
        );
      }
      return '';
    },
  },
};
</script>

<template>
  <div class="debug position-relative">
    <div class="header position-absolute" v-if="title">
      {{ title }}
    </div>
    <pre class="json" v-if="isTestEnv">
      <code v-html="highlightedJson"></code>
    </pre>
    <div class="copy-container position-absolute cursor-pointer d-flex align-items-center justify-content-center" @click="copyJson">
      <img src="@/assets/icons/copy-2.svg" alt="Copy" class="icon-copy">
    </div>
  </div>
</template>

<style lang="scss">
$json-background: #282c34;
$json-color: #b4b4b4;
$json-key-color: #e6566c;
$json-string-color: #7ab16c;
$json-number-color: #c99464;
$json-array-color: #b4b4b4;
$json-object-color: #b4b4b4;
$json-null-color: #ca5cd3;
$json-boolean-color: #ca5cd3;

.debug {
  .header {
    top: 10px;
    right: 50px;

    font-family: "Artifex CF Regular", sans-serif;
    font-size: 12px;

    color: rgba(255, 255, 255, 0.7);

    padding: 10px;
  }

  .copy-container {
    top: 20px;
    right: 20px;

    width: 25px;
    height: 25px;

    background: rgba(255, 255, 255, 0.01);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.05);

    .icon-copy {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(260deg) brightness(101%) contrast(102%);
    }
  }

  .json {
    background-color: $json-background;
    color: $json-color;
    padding: 10px;
    font-family: monospace;
    overflow-x: auto;
    margin: 0;

    font-size: 10px;

    line-height: 1.2em;
    max-height: calc(1.2em * 15);

    code {
      margin: 0;
      padding: 0;
      display: block;
    }

    .key {
      color: $json-key-color;
    }

    .string {
      color: $json-string-color;
    }

    .number {
      color: $json-number-color;
    }

    .array {
      color: $json-array-color;
    }

    .object {
      color: $json-object-color;
    }

    .null {
      color: $json-null-color;
    }

    .boolean {
      color: $json-boolean-color;
    }
  }
}

</style>

