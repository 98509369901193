<script>
export default {

}
</script>

<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.75" d="M10.273 1.08034L1.08058 10.2727M1.08058 0.72679L10.273 9.91918" stroke="white" stroke-width="1.5"/>
  </svg>
</template>

<style scoped lang="scss">

</style>