<script>
export default {
  name: "InfinityMenu",
  props: {
    subItems: {
      type: Array,
      default: () => []
    },
    maxDepth: {
      type: Number,
      default: 1
    },
    depth: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showChildren: false,
      expanded: false,
      containerHeight: 0,
    };
  },
  computed: {
    getIcon() {
      return this.expanded ? 'arrow-control-up.svg' : 'arrow-control-down.svg';
    },
  },

  methods: {
    dynamicSlotName(n) {
      return `depth-${n}`;
    },

    hasSubItems() {
      return this.subItems && this.subItems.length > 0;
    },

    toggleMenu() {
      this.expanded = !this.expanded;
      if (!this.showChildren) {
        this.showChildren = true;
        this.$nextTick(() => {
          this.containerHeight = this.$refs.container.scrollHeight + "px";
          setTimeout(() => {
            this.containerHeight = "fit-content";
          }, 300);
        });
        return;
      }
      this.containerHeight = this.$refs.container.scrollHeight + "px";
      this.$refs.container.style.overflow = "hidden";
      setTimeout(() => {
        this.containerHeight = 0 + "px";
      }, 10);
      setTimeout(() => {
        this.showChildren = false;
      }, 300);
    }
  },
  mounted() {
    this.toggleMenu();
  }
};
</script>

<template>
  <div class="menu-item">
    <slot :name="'depth-' + depth" :item="item" :toggleMenu="toggleMenu" :icon="getIcon" :isOpen="expanded"></slot>

    <div v-show="showChildren" class="items-container overflow-hidden" :style="{ height: containerHeight }"
         ref="container">
      <InfinityMenu
          v-for="(subItem, index) in subItems"
          :key="index"
          :subItems="subItem.subItems"
          :depth="depth + 1"
          :item="subItem"
          :maxDepth="maxDepth - 1"
      >
        <template v-for="n in maxDepth" :key="n" v-slot:[dynamicSlotName(n)]="{item, toggleMenu, icon, isOpen}">
          <slot :name="dynamicSlotName(n)" :item="item" :toggleMenu="toggleMenu" :icon="icon" :isOpen="isOpen"></slot>
        </template>
      </InfinityMenu>
    </div>
  </div>
</template>

<style scoped>
.menu-item {
  padding-top: 5px;
}

.custom-card-body > .menu-item:first-child {
  padding-top: 0;
}

.items-container > .menu-item:first-child {
  padding-top: 5px;
}

.items-container {
  transition: all 0.4s ease-in-out;
}

</style>
