import {createApp} from 'vue';
import ToastManager from './ToastManager.vue';
import {v4 as uuidv4} from 'uuid';
import {ToastType} from "@/components/toast/ToastType";

let toastInstance = null;

const ToastPlugin = {
    install(app) {
        const ToastConstructor = createApp(ToastManager);
        const instance = ToastConstructor.mount(document.createElement('div'));
        document.body.appendChild(instance.$el);

        const defaultDuration = 3000;

        app.config.globalProperties.$toast = {
            show(description, title = '', type = ToastType.INFO, duration = defaultDuration) {
                const id = uuidv4();
                instance.addToast({description, title, type, duration, id});
                return {id};
            },

            alert(description, title, duration = defaultDuration) {
                return this.show(description, title, ToastType.ALERT, duration);
            },

            success(description, title = 'Success', duration = defaultDuration) {
                return this.show(description, title, ToastType.SUCCESS, duration);
            },

            error(description, title = 'Error', duration = defaultDuration) {
                return this.show(description, title, ToastType.ERROR, duration);
            },

            info(description, title = 'Info', duration = defaultDuration) {
                return this.show(description, title, ToastType.INFO, duration);
            },

            loading(description, duration = 0) {
                return this.show(description, '', ToastType.LOADING, duration);
            },

            update(id, updatedProps) {
                const defaultProps = {
                    title: titleByType(updatedProps.type, updatedProps.title),
                    duration: defaultDuration
                }

                instance.updateToast(id, {...defaultProps, ...updatedProps});
            }
        };

        toastInstance = app.config.globalProperties.$toast;
    },
};

function titleByType(type, title) {
    if (title) {
        return title;
    }

    switch (type) {
        case ToastType.ALERT:
            return 'Alert';
        case ToastType.ERROR:
            return 'Error';
        case ToastType.SUCCESS:
            return 'Success';
        case ToastType.INFO:
            return 'Info';
        default:
            return '';
    }
}

export { toastInstance };
export default ToastPlugin;