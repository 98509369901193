<script>
import AlertIcon from "@/assets/icons/toast/AlertIcon.vue";
import PositiveIcon from "@/assets/icons/toast/PositiveIcon.vue";
import NegativeIcon from "@/assets/icons/toast/NegativeIcon.vue";
import InfoIcon from "@/assets/icons/toast/InfoIcon.vue";
import CloseIcon from "@/assets/icons/toast/CloseIcon.vue";
import {ToastType} from "@/components/toast/ToastType";
import Loading from "@/components/Loading.vue";
import {LoadingType} from "@/type/LoadingType";

export default {
  components: {Loading, CloseIcon, AlertIcon},
  props: {
    description: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    duration: {
      type: Number,
    },
    index: {
      type: Number,
      required: true,
    },
    initialProgress: {
      type: Number,
      default: 100,
    }
  },

  computed: {
    LoadingType() {
      return LoadingType
    },
    ToastType() {
      return ToastType
    },

    getIconByType() {
      switch (this.type) {
        case 'success':
          return PositiveIcon
        case 'error':
          return NegativeIcon
        case 'info':
          return InfoIcon
        case 'alert':
          return AlertIcon
        case 'loading':
          return null
        default:
          return null
      }
    },

    formattedDescription() {
      return this.description
          .replace(/<#>/g, '<b>')
          .replace(/<\/#>/g, '</b>')
          .replace(/<n>/g, '<br>');
    }
  },

  data() {
    return {
      isVisible: true,
      progress: this.initialProgress,

      progressInterval: null
    };
  },

  watch: {
    message: {
      handler() {
        this.resetProgress();
      },
    },
    type: {
      handler() {
        this.resetProgress();
      },
    },

    duration: {
      handler() {
        this.resetProgress();
      }
    },

    initialProgress(newValue) {
      if (newValue === 100) {
        this.startTimeout();
      }
    }
  },

  methods: {
    closeToast() {
      this.$emit('hide');
    },

    resetProgress() {
      clearInterval(this.progressInterval);
      this.progress = 100;
      this.startTimeout();
    },

    startTimeout() {
      if (this.duration === 0) return;

      const interval = 10;
      const decrement = (interval / this.duration) * 100;

      this.progressInterval = setInterval(() => {
        this.progress -= decrement;
        if (this.progress <= 0) {
          clearInterval(this.progressInterval);
          this.closeToast();
        }
      }, interval);
    },
  },

  mounted() {
    this.startTimeout();
  },
};
</script>

<template>
  <div
      class="toast"
      :data-toast="type"
  >
    <div class="body d-flex">
      <div class="icon-container d-flex align-items-center justify-content-center"
           v-if="this.type !== ToastType.LOADING">
        <component :is="getIconByType"/>
      </div>

      <div class="icon-loading d-flex align-items-center justify-content-center" v-if="this.type === ToastType.LOADING">
        <Loading :type="LoadingType.SMALL"/>
      </div>

      <div class="message-container flex-grow-1">
        <div class="title" v-if="title && this.type !== ToastType.LOADING">
          {{ title }}
        </div>
        <div class="body"
             :class="[this.type === ToastType.LOADING ? 'h-100 d-flex align-items-center' : '']"
             v-html="formattedDescription"
        />
      </div>

      <div class="close-button cursor-pointer my-auto d-flex align-items-center justify-content-center"
           @click="closeToast" v-if="this.type !== ToastType.LOADING">
        <CloseIcon/>
      </div>
    </div>

    <div class="progress" v-if="this.type !== ToastType.LOADING">
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
[data-toast='alert'] {
  --toast-bg: linear-gradient(0deg, rgba(52, 47, 35, 1) 0%, rgba(100, 93, 74, 0.5) 100%);
  --toast-border: rgba(255, 231, 184, 0.1);
  --toast-progress: rgba(255, 231, 184, 0.75);

  --toast-icon-bg: rgba(255, 231, 184, 0.1);
  --toast-icon-border: rgba(255, 231, 184, 0.25);
}

[data-toast='error'] {
  --toast-bg: linear-gradient(0deg, rgba(50, 37, 37, 1) 0%, rgba(96, 77, 76, 0.5) 100%);
  --toast-border: rgba(255, 184, 184, 0.1);
  --toast-progress: rgba(255, 184, 184, 0.75);

  --toast-icon-bg: rgba(255, 184, 184, 0.1);
  --toast-icon-border: rgba(255, 184, 184, 0.25);
}

[data-toast='success'] {
  --toast-bg: linear-gradient(0deg, rgba(37, 50, 39, 1) 0%, rgba(77, 96, 81, 0.5) 100%);
  --toast-border: rgba(184, 255, 208, 0.1);
  --toast-progress: rgba(184, 255, 208, 0.75);

  --toast-icon-bg: rgba(184, 255, 208, 0.1);
  --toast-icon-border: rgba(184, 255, 208, 0.25);
}

[data-toast='info'] {
  --toast-bg: linear-gradient(0deg, rgba(37, 46, 50, 1) 0%, rgba(77, 90, 96, 0.5) 100%);
  --toast-border: rgba(184, 232, 255, 0.1);
  --toast-progress: rgba(184, 232, 255, 0.75);

  --toast-icon-bg: rgba(184, 232, 255, 0.1);
  --toast-icon-border: rgba(184, 232, 255, 0.25);
}

[data-toast='loading'] {
  --toast-bg: linear-gradient(0deg, rgba(37, 46, 50, 1) 0%, rgba(77, 90, 96, 0.5) 100%);
  --toast-border: rgba(184, 232, 255, 0.1);
  --toast-progress: rgba(255, 0, 0, 1);

  --toast-icon-bg: rgba(255, 0, 0, 1);
  --toast-icon-border: rgba(255, 0, 0, 1);
}

.toast {
  position: relative;
  display: flex;
  flex-direction: column;

  background: var(--toast-bg);
  border: 1px solid var(--toast-border);

  border-radius: 2px;

  z-index: 9999;
  width: 340px;

  opacity: 1;

  backdrop-filter: blur(10px);

  .icon-container {
    padding: 15px;

    border-top-left-radius: 2px;

    border: 1px solid var(--toast-icon-border);
    background: var(--toast-icon-bg);
  }

  .icon-loading {
    padding: 2px 20px;
  }

  .close-button {
    padding: 3px;
    margin-right: 10px;

    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
  }

  .message-container {
    padding: 10px;

    .title {
      font-family: "Artifex CF Bold", sans-serif;
      font-size: 14px;

      color: #FFFFFF;

      padding-bottom: 5px;
    }

    .body {
      font-family: "Artifex CF Extra Light", sans-serif;
      font-size: 12px;

      color: rgba(255, 255, 255, 0.75)
    }
  }

  .progress {
    width: 100%;
    height: 2px;
    background-color: transparent;
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    .progress-bar {
      height: 100%;
      background-color: var(--toast-progress);
      transition: width linear;
    }
  }
}
</style>
