import {toastInstance} from "@/components/toast/Tostify";

export const errorUtil = {
    catchErrors
};

const errors = new Map([
    ["missing-field", "A required field is missing."],
    ["invalid-json", "The provided JSON is invalid."],
    ["missing-json", "The request body is missing."],
    ["invalid-type", "The type of the field is incorrect."],
    ["invalid-format", "The field has an invalid format."],
    ["invalid-parameter-format", "A parameter has an invalid format."],
    ["product", "The specified product does not exist."],
    ["group", "The specified group does not exist."],
    ["item", "The specified item does not exist."],
    ["from_group", "The source group does not exist."],
    ["to_group", "The destination group does not exist."]
]);

const ignoredErrors = new Set([

]);

function catchErrors(error, toast) {
    const errorData = error.response.data

    if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
        console.error('Error:', errorData)
    }
    
    if (ignoredErrors.has(errorData.details.reason)) {
        return null; 
    }

    let errorMessage = "An unknown error occurred.";

    if (errorData.details) {
        if (errorData.details.reason && errors.has(errorData.details.reason)) {
            errorMessage = errors.get(errorData.details.reason);
        }
        if (errorData.details.path) {
            errorMessage += ` Path: <#>${errorData.details.path}</#>`;
        }
    }

    if (errorData.status) {
        switch (errorData.status) {
            case 400:
                errorMessage = "Bad request. " + errorMessage;
                break;
            case 404:
                errorMessage = "Resource not found. " + errorMessage;
                break;
            case 500:
                errorMessage = "Internal server error.";
                break;
            default:
                errorMessage = `Error ${errorData.status}: ` + errorMessage;
        }
    }

    if (toast) {
        toastInstance.update(toast.id, {
            title: "ErrorHandler",
            description: errorMessage,
            type: 'error'
        })
    } else {
        toastInstance.error(errorMessage, 'Error')
    }

    return errorMessage
}